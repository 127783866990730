import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
@Pipe({
    name: 'currency',
  })
  export class CurrencyNlProxyPipe implements PipeTransform {
    transform(
    value: any,
    currencyCode = "EUR",
    display = "symbol",
    digitsInfo = "1.2-2",
    locale = "nl"
  ) {
        return formatCurrency(
          value,
          locale,
          getCurrencySymbol(currencyCode, 'wide'),
          currencyCode,
          digitsInfo,
        );
    }
}
