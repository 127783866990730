import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { tap } from 'rxjs/operators/tap';


// check is user is authenticated, so he can procees to pages folder
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: NbAuthService, private router: Router) {
  }

  canActivate() {
    return this.authService.isAuthenticatedOrRefresh()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
          }
        }),
      );
  }
}



// ROLE: RECEPTION
@Injectable()
export class AuthGuardMaster implements CanActivate {

  constructor(private authService: NbAuthService, private router: Router) {
  }

  canActivate() {
       return this.authService.isAuthenticatedOrRefresh()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
          } else {
            this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {

              if (token.isValid()) {

                if (token.getPayload()['fs_sign'] != 'nBi2bkjd8ub2j') {
                  this.router.navigate(['auth/login']);
                }

                if (token.getPayload()['role'] > 2) {
                  this.router.navigate(['/p/payments']);
                } 
              }
              else {
                this.router.navigate(['auth/login']);
              }

            });
          }
        }),
      );
  }
}


// ROLE: RECEPTION
@Injectable()
export class AuthGuardPayments implements CanActivate {

  constructor(private authService: NbAuthService, private router: Router) {
  }

  canActivate() {
       return this.authService.isAuthenticatedOrRefresh()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
          } else {
            this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {

              if (token.isValid()) {

                if (token.getPayload()['fs_sign'] != 'nBi2bkjd8ub2j') {
                  this.router.navigate(['auth/login']);
                }
                
                if (token.getPayload()['role'] > 3) {
                  this.router.navigate(['auth/login']);
                } 
              }
              else {
                this.router.navigate(['auth/login']);
              }

            });
          }
        }),
      );
  }
}