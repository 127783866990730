import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  // NbRegisterComponent,
  // NbRequestPasswordComponent,
  // NbResetPasswordComponent,
} from '@nebular/auth';

import { AuthGuard } from './auth-guard.service';

export const routes: Routes = [
  {
    path: 'p',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
      canActivate: [AuthGuard],
  },

  {
    path: 'open',
    loadChildren: () => import('./open/open.module')
      .then(m => m.OpenModule),
  },

  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module')
      .then(m => m.NgxAuthModule),
  },

  { path: '', redirectTo: 'p', pathMatch: 'full' },
  { path: '**', redirectTo: 'p' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
