import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';



// **********************
// PRODUCTION OR DEV
const production = true; // <----------------------------
// API CONSTANTS
let apiUrl = production ? 'https://stek-fh.1folds.com/fs-api/api' : 'http://localhost:8000/api';
let rootUrl = production ? 'https://stek-fh.1folds.com/fs-api/' : 'http://localhost:8000/';
// **********************


export const dev = {
	auth: apiUrl,
	production: production,
}


@Injectable()
export class ApiService {

	// BASICS
	baseUrl() {
		return rootUrl;
	}



	constructor(private http: HttpClient) {	}


	// GENERAL
	getBasics() {
		return this.http.get(apiUrl+'/general/basics');
	}

	// VISITORS
	searchAllVisitors(search, status_all = 0, visitor_id = 0) {
		return this.http.get(apiUrl+'/visitors/search/' + search + '/' + status_all + '/' + visitor_id);
	}
	getVisitor(visitor_id) {
		return this.http.get(apiUrl+'/visitors/get/' + visitor_id);
	}
	updateVisitorByField(data) {
		return this.http.post(apiUrl+'/visitors/updateField', data);
	}
	newClient(data) {
		return this.http.post(apiUrl+'/visitors/new', data);
	}

	// Dashboard
	getDashboardStats(data:any = []) {
		return this.http.post(apiUrl+'/dashboard/stats/all', data);
	}
	getDashboardStatsGraphYear(data:any = []) {
		return this.http.post(apiUrl+'/dashboard/stats/graph/year', data);
	}
	getDashboardStatsGraphWeek(data:any = []) {
		return this.http.post(apiUrl+'/dashboard/stats/graph/week', data);
	}

	// STATS
	getStats1(data:any = []) {
		return this.http.post(apiUrl+'/stats/1/all', data);
	}
	getStats1Client(data:any = []) {
		return this.http.post(apiUrl+'/stats/1/client', data);
	}

	// APPLICATIONS
	getApplicationsWithStatus(status) {
		return this.http.get(apiUrl+'/applications/status/' + status);
	}
	getApplications2(data) {
		return this.http.post(apiUrl+'/applications/2', data);
	}
	getApplicationsPayments() {
		return this.http.get(apiUrl+'/applications/payments');
	}
	getLastApplications(visitor_id, application_id) {
		return this.http.get(apiUrl+'/applications/last/' + visitor_id + '/' + application_id);
	}
	getApplicationById(application_id) {
		return this.http.get(apiUrl+'/applications/byId/' + application_id);
	}
	updateApplicationStatus(data) {
		return this.http.post(apiUrl+'/applications/updateStatus', data);
	}
	updateApplicationPayoutType(data) {
		return this.http.post(apiUrl+'/applications/updatePayoutType', data);
	}
	saveApplication(data) {
		return this.http.post(apiUrl+'/applications/save', data);
	}
	createVisitorByApplication(data) {
		return this.http.post(apiUrl+'/applications/newVisitor', data);
	}
	downloadINGBank(data) {
		return this.http.post(apiUrl+'/applications/downloadINGBank', {data:data});
	}
	exportPayments(data) {
		return this.http.post(apiUrl+'/applications/exportPayments', data);
	}
	exportPaymentsKas(data) {
		return this.http.post(apiUrl+'/applications/exportPaymentsKas', data);
	}
	newApplication() {
		return this.http.post(apiUrl+'/applications/new', {});
	}

	sendNewEmail(data) {
		return this.http.post(apiUrl+'/emails/new', data);
	}



	// OPEN
	getOpenBasics() {
		return this.http.get(rootUrl+'open/form1/basics');
	}
	getOpenOrganisations() {
		return this.http.get(rootUrl+'open/form1/organisations');
	}
	getOpenCountries() {
		return this.http.get(rootUrl+'open/form1/countries');
	}

	submitForm1(data) {
		return this.http.post(rootUrl+'open/form1/submit', data);
	}

	// TESTS
	emailTest() {
		return this.http.get(apiUrl+'/emails/test');
	}
	appTest() {
		return this.http.get(apiUrl+'/applications/test', {responseType: 'blob'});
	}

	// MESSAGES
	getMessages(skip_end = 0, take = 20) {
		return this.http.get(apiUrl+'/messages/all/' + skip_end + '/' + take);
	}
	createMessage(data) {
		return this.http.post(apiUrl+'/messages/create', data);
	}
	updateMessage(data) {
		return this.http.post(apiUrl+'/messages/update', data);
	}


	// EMAILS
	getEmails(skip_end = 0, take = 20, status: string | number = -1) {
		return this.http.get(apiUrl+'/emails/all/' + skip_end + '/' + take + '/' + status);
	}
	getEmails2(data:any) {
		return this.http.post(apiUrl+'/emails/all2', data);
	}
	updateEmailStatus(id, status) {
		return this.http.get(apiUrl+'/emails/update/' + id + '/' + status);
	}
	saveEmail(data) {
		return this.http.post(apiUrl+'/emails/save', data);
	}

	// LOGBLOCKS
	getLogblocks(id, search:string, tags) {
		return this.http.get(apiUrl+'/logblocks/get/' + id + '/' + search + '/' + tags);
	}
	addLogblocks(id) {
		return this.http.get(apiUrl+'/logblocks/add/' + id);
	}
	updateLogblocks(data) {
		return this.http.post(apiUrl+'/logblocks/update', {data});
	}
	updateLogblockTags(data) {
		return this.http.post(apiUrl+'/logblocks/update-tags', {data});
	}


	// SETTINGS - MU
	getAllUsers() {
		return this.http.get(apiUrl+'/settings/users');
	}
	getRoles() {
		return this.http.get(apiUrl+'/settings/roles');
	}
	getSmsSettings() {
		return this.http.get(apiUrl+'/settings/sms/all');
	}
	editSmsSettings(data) {
		return this.http.post(apiUrl+'/settings/sms/edit', data);
	}
	getEmailSettings() {
		return this.http.get(apiUrl+'/settings/email/all');
	}
	editEmailSettings(data) {
		return this.http.post(apiUrl+'/settings/email/edit', data);
	}
	postUsers(data) {
		return this.http.post(apiUrl+'/settings/users/edit', {data: data});
	}
	newUser(data) {
		return this.http.post(apiUrl+'/settings/users/new', {data: data});
	}
	getOrganisationsSettings() {
		return this.http.get(apiUrl+'/settings/organisations/all');
	}
	updateOrganisationsSettings(data) {
		return this.http.post(apiUrl+'/settings/organisations/edit', data);
	}
	getTagsSettings() {
		return this.http.get(apiUrl+'/settings/tags/all');
	}
	updateTagsSettings(data) {
		return this.http.post(apiUrl+'/settings/tags/edit', data);
	}

}
